export default {
  primary: "#20ae61",
  primaryDark: "#2b4f7f",
  primaryText: "#ffffff",
  primaryBackground: "#edf5ff",
  secondaryBackground: "#ffffff",
  accent: "#feca57",
  secondary: "#ffffff",
  secondaryText: "#c0c4cc",
  tertiary: "#e0e2e9",
  text: "#101010",
  window: "#f5f5f5",
  highlight: "#a4ccff",
  highlightBackground: "#f6faff",
  success: "#4caf50",
  successBackground: "#edf7ed",
  successLight: "#a0d4a7",
  danger: "#ef5350",
  dangerBackground: "#feeded",
  dangerLight: "#f7a3a2",
  star: "#fecb57",
};
