import React, { useContext } from "react";
import fonts from "../../fonts/fonts.js";

import { ConfigContext } from "../../shared/contexts/ConfigContext";
import { Wrapper, Title, WrapTitle } from "./styles";

function Header({ componentLeft, title, componentRigth }) {
  const { headerVisible } = useContext(ConfigContext);

  if (!headerVisible) return null;

  return (
    <Wrapper>
      {componentLeft ? componentLeft : <div style={{ flex: 1 }}></div>}
      <Title style={{ fontFamily: fonts.primary }}>{title}</Title>
      {componentRigth ? componentRigth : <div style={{ flex: 1 }}></div>}
    </Wrapper>
  );
}

export default Header;
