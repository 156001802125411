import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./shared/contexts/AuthContext";

const PrivateRoute = ({ children, ...rest }) => {
  const { logged } = useContext(AuthContext);

  React.useEffect(() => {
    console.log("Usuário Logado ", logged);
  });

  return (
    <Route
      {...rest}
      render={({ location }) =>
        logged ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
