import styled from "styled-components";
import colors from "../../shared/colors";

export const Wrapper = styled.footer`
  width: 100vw;
  height: 80px;
  position: fixed;
  bottom: 0;
  background-color: ${colors.primaryText};
  display: flex;
  justify-content: space-between;
  border-top-width: 1px;
  border-color: #717171;
  box-shadow: 7px 8px 6px 6px black;
`;

export const Item = styled.div`
  flex: 1;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  span {
    font-size: 26px;
    color: ${colors.secondaryText};
  }
`;
