import React, { useContext } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { ConfigContext } from "../../shared/contexts/ConfigContext";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function Loading() {
  const classes = useStyles();
  const { loadingVisible } = useContext(ConfigContext);

  return (
    <Backdrop className={classes.backdrop} open={loadingVisible}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default Loading;
