import React from "react";
import Header from "../Header";
import TabBottom from "../TabBottom";
import Loading from "../Loading";

function Layout({ children }) {
  return (
    <div>
      <main>{children}</main>

      <Loading />
      <TabBottom />
    </div>
  );
}

export default Layout;
