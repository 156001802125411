import React from "react";
import Routes from "./Routes";
import Loading from "./components/Loading";
import "./index.css";

const App = () => (
  <>
    <Routes />
  </>
);

export default App;
