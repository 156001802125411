import styled from "styled-components";
import colors from "../../shared/colors";
import fonts from "../../fonts/fonts.js";

export const Wrapper = styled.div`
  width: 100%;
  height: 80px;
  background-color: ${colors.primary};
  display: flex;
  justify-content: space-between;
  font-family: ${fonts.secondary};
  position: fixed;
  z-index: 10;
`;

export const Title = styled.div`
  flex: 2;
  font-size: 22px;
  font-family: ${fonts.secondary};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const SearchWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: ${fonts.secondary};

  span {
    font-size: 2rem;
    color: white;
  }
`;

export const FilterWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${fonts.secondary};
  color: white;
  cursor: pointer;

  span {
    font-size: 2rem;
    color: white;
    margin-right: 10px;
  }
`;
